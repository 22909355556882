import React from "react";

export const VisaLink = () => {
  return (
    <div className="flex justify-center mt-[3rem]">
      <a
        href="http://www.online.nepalimmigration.gov.np/tourist-visa"
        target="_blank"
        className="bg-white shadow-lg rounded-md flex items-center justify-center px-5 border-gray-400 border-[1px] h-[3.5rem] font-display text-xl"
      >
        NEPAL VISA APPLICATION LINK
      </a>
    </div>
  );
};
