import React, { useEffect, useState } from "react";
import { ApiDataProvider } from "./components/ApiDataProvider";
import { Home } from "./components/Home";
import { Nav } from "./common/Nav";
import Countdown from "./components/Countdown";
import { Event } from "./components/Event";
import { People } from "./components/People";
import { Gallery } from "./components/Gallery";
import { Rsvp } from "./components/Rsvp";
import { Footer } from "./components/Footer";
import { Faqs } from "./components/Faqs";
import { Contact } from "./components/Contact";
import { VisaLink } from "./components/VisaLink";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

const Content = () => {
  const customStyles = {
    content: {
      // width: "50%",
      // top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 5000,
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000";
    subtitle.style.fontWeight = 700;
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    openModal();
  }, []);

  return (
    <ApiDataProvider>
      <Modal
        className={
          "w-[35%] mobileM:w-[90%] absolute top-[50%] right-auto bottom-auto bg-white outline-none p-5 rounded-xl border-gray-400 border-[2px] shadow-2xl"
        }
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="flex justify-between">
          <h2 ref={(_subtitle) => (subtitle = _subtitle)} className="text-4xl font-extrabold">
            Reminder
          </h2>
          <button onClick={closeModal} className="text-xl">
            <MdClose />
          </button>
        </div>
        <hr className="mb-10 mt-1" />
        <div className="text-xl font-extrabold">
          Reminder- Date Overdue
        </div>
        <br />
      </Modal>

      <div className="bg-gray-200">
        <Home />
        <Nav />
        {/* <Couple /> */}
        <Countdown />
        {/* <Story /> */}
        <Event />
        <People />
        <VisaLink />
        <Gallery />
        <Rsvp />
        <Faqs />
        {/* <Contact /> */}
        <Footer />
      </div>
    </ApiDataProvider>
  );
};

export default Content;
