import React from "react";
import { useApiData } from "./ApiDataProvider";

export const Home = () => {
  const { data } = useApiData();

  const bgUrl = data?.backgrounds?.[0]?.image?.fileUrl;

  return (
    <div id="home">
      <div
        // style={{ backgroundImage: (`${bgUrl}`) }}
        className={`bg-weddingbg bg-cover bg-no-repeat bg-center bgSize:bg-top min-h-screen min-w-full`}
      >
        <div
          className={`bg-black min-h-screen bg-opacity-40 flex flex-col items-center justify-end pb-28`}
        >
          <div className="flex justify-center tablet:w-[70%] mobileM:w-[92%] laptop1:w-[40%] mt-[1rem]">
            <h1
              className="text-white text-4xl mobileS:text-2xl mobileM:text-2xl font-display mb-[2rem] font-extrabold"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              {data?.brideName?.split(" ")?.[0]} &{" "}
              {data?.groomName?.split(" ")?.[0]}
            </h1>
          </div>
          <div className="bg-white tablet:w-[70%] laptop1:w-[40%] mobileM:w-[92%] h-[1px] mb-[1rem] flex"></div>
          <h1
            data-aos="zoom-in"
            data-aos-duration="1500"
            className="tablet:w-[70%] mobileM:w-[92%] laptop1:w-[40%]  mt-[1rem] flex items-center justify-center text-white tracking-[0.4rem] font-display tablet:text-2xl mobileS:text-sm"
          >
            WE'RE GETTING MARRIED
          </h1>
          <div className="bg-white tablet:w-[70%] mobileM:w-[92%] laptop1:w-[40%] h-[1px] mt-[1rem] flex"></div>
        </div>
      </div>
    </div>
  );
};
