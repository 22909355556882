import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Content from "./Content";
import NotFoundPage from "./components/NotFoundPage";
import Traveldetail from "./Traveldetail";
import Memory from "./Memory";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Content />} />
      <Route path="travel/:eventId" element={<Traveldetail />} />
      <Route path="memories/:eventId" element={<Memory />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
