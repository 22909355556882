/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import people from "../data/people";
import Layout from "../common/Layout";
import { useApiData } from "./ApiDataProvider";

export const People = () => {
  const { data } = useApiData();
  console.log(data, "data");
  const peopleData = people;

  const [gender, setGender] = useState("male");
  const [filteredPeople, setFilteredPeople] = useState([]);

  const contact = data?.closeFriends.map((friend) => ({
    id: friend?._id,
    image: friend?.image?.image?.fileUrl,
    name: friend?.name,
    relation: friend?.relation,
    phone: friend?.phone,
  }));

  useEffect(() => {
    const data = peopleData.filter((item) => item.gender === gender);
    setFilteredPeople(data);
  }, [gender]);

  console.log(filteredPeople, "filtered people");

  return (
    <div
      id="contact"
      className="flex flex-col items-center justify-center pt-[3rem] gap-6"
    >
      <div className="flex items-center justify-center w-[15rem] border-gray-400 border-[1px] h-[3.5rem] bg-white shadow-lg rounded-md">
        <h1 className="font-display text-xl">EMERGENCY CONTACT</h1>
      </div>
      {/* <div className="flex gap-6">
        <button
          className="h-[2.5rem] w-[8rem] font-display bg-gray-300"
          onClick={() => setGender("male")}
        >
          Groomsmen
        </button>
        <button
          className="h-[2.5rem] w-[8rem] font-display bg-gray-300"
          onClick={() => setGender("female")}
        >
          Bridesmaid
        </button>
      </div> */}
      <Layout style={{ height: "fit-content" }}>
        <div className="grid laptop1:grid-cols-3 gap-7 pt-[3rem] tablet:grid-cols-2">
          {contact?.map((friend, index) => {
            return (
              <div
                key={index}
                className="group  border-gray-400 shadow-lg bg-white rounded-md"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                {/* <div className=" overflow-hidden  ">
                  <img
                    className="group-hover:scale-125 duration-150 group-hover:border-red-400 group-hover:rounded-t-[1rem] contrast-75 group-hover:contrast-125 w-full h-[20rem] object-cover object-top"
                    src={friend?.image}
                  />
                </div> */}
                <div className="p-[1rem] border-gray-400  ">
                  <h1 className="flex font-display tracking-[1px] leading-7">
                    Name: {friend?.name}
                  </h1>

                  {/* <h1 className="font-display text-black tracking-[0.1px]">
                    {friend?.name}
                  </h1> */}
                  <h1 className="font-display text-black tracking-[1px] leading-7">
                    Designation: {friend?.relation}
                  </h1>
                  {(friend?.relation === "Groom" ||
                    friend?.relation === "Bride") && (
                    <h1 className="text-xs">
                      (<strong>Note:</strong> PLEASE REFRAIN FROM CONTACTING
                      MEDHA AND INDERPREET DURING THE WEDDING UNLESS THE
                      SITUATION IS CRITICAL. IT IS IMPERATIVE TO RESPECT THEIR
                      TIME AND ENSURE MINIMAL DISRUPTION DURING THIS IMPORTANT
                      OCCASION.)
                    </h1>
                  )}
                  <h1 className="font-display text-black tracking-[0.8px] leading-7">
                    Number:&nbsp;
                    <a href={`https://wa.me/${friend?.phone}`} target="_blank">
                      {friend?.phone}
                    </a>
                  </h1>
                </div>
              </div>
            );
          })}
        </div>
      </Layout>
    </div>
  );
};
