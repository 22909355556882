import React, { useEffect, useState } from "react";
import event from "../data/event";
import people from "../data/people";
import Layout from "../common/Layout";
import { useApiData } from "./ApiDataProvider";
import moment from "moment/moment";

export const Event = () => {
  const { data } = useApiData();

  const days = data?.days?.map((day) => ({
    id: day?._id,
    img: day?.image?.image?.fileUrl,
    heading: day?.title,
    location: day?.location,
    time: day?.dateTime,
    paragraph: day?.description,
    theme: day?.theme,
    dressCode: day?.dressCode,
    area: day?.area,
  }));

  const peopleData = people;

  const [gender, setGender] = useState("male");
  const [filteredPeople, setFilteredPeople] = useState([]);

  useEffect(() => {
    const data = peopleData.filter((item) => item.gender === gender);
    setFilteredPeople(data);
  }, [gender]);

  return (
    <div
      id="event"
      className="flex flex-col items-center justify-center pt-[6rem] overflow-hidden"
    >
      <div className=" flex items-center justify-center w-[15rem] border-gray-400 border-[1px] h-[3.5rem] bg-white">
        <h1 className="text-xl font-display ">WEDDING EVENTS</h1>
      </div>
      <Layout
        style={{
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {days?.map((event, index) => {
          return (
            <div
              key={index}
              className={`flex flex-row mobileTablet:flex-col gap-5 mt-[3rem] justify-center w-[fit-content] shadow-xl rounded-xl overflow-hidden bg-white ${
                index === days?.length - 1 && "mb-10"
              }`}
            >
              <img
                data-aos="fade-right"
                data-aos-duration="1500"
                src={event?.img}
                className="flex-[1]  w-[20rem] shadow-md object-cover mobileTablet:w-full"
              />
              <div
                data-aos="fade-left"
                data-aos-duration="1500"
                className="flex flex-col mx-3 mb-3 flex-[1] mt-3"
              >
                <h1 className="font-display text-xl font-semibold">
                  {event?.heading}
                </h1>
                <h1 className="font-display text-[1rem]  pt-[0.5rem] text-sm text-gray-800">
                  {event?.location}
                </h1>
                <br />
                <div className="flex items-center gap-3">
                  <h1 className="font-display text-[1rem]  text-gray-800">
                    {moment
                      .utc(event?.time)
                      .format("dddd, MMMM D, YYYY, h:mm A")}{" "}
                    onwards <br />
                    {event?.heading === "Anand Karaj" &&
                      "(Departure from hotel sharp 8.30am)"}
                  </h1>
                  {/* <h1 className="font-display text-[1.1rem]  text-gray-800">
                    {moment.utc(event?.time).format("h:mm A")}
                  </h1> */}
                </div>

                {event?.theme && event?.theme !== "undefined" && (
                  <div className="flex gap-1 mt-1 font-bold text-sm">
                    <h2>Theme: </h2>
                    <h2>{event?.theme}</h2>
                  </div>
                )}

                {event?.area && event?.area !== "undefined" && (
                  <div className="flex gap-1 mt-1 font-bold text-sm">
                    <h2>Event Area: </h2>
                    <h2>{event?.area}</h2>
                  </div>
                )}

                {event?.dressCode && event?.dressCode !== "undefined" && (
                  <div
                    className={`flex gap-1 mt-1 ${
                      event.dressCode.toLowerCase() ===
                      "Details for Dress Code will be followed soon".toLowerCase()
                        ? "font-normal underline"
                        : "font-bold"
                    } text-sm`}
                  >
                    <h2>Dress Code: </h2>
                    <h2>{event?.dressCode}</h2>
                  </div>
                )}
                <div></div>
                <div></div>
                <h1
                  className="font-display text-[1rem] pt-[1rem] max-w-[30rem] text-gray-800"
                  dangerouslySetInnerHTML={{ __html: event?.paragraph }}
                >
                  {/* {event?.paragraph} */}
                </h1>
              </div>
            </div>
          );
        })}
      </Layout>
    </div>
  );
};
