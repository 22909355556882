import React from "react";
import Layout from "../common/Layout";
import { useApiData } from "./ApiDataProvider";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const Rsvp = () => {
  const navigate = useNavigate();

  const [inputValue, setInputvalue] = useState({
    email: "",
    name: "",
    address: "",
    phone: "",
    numberOfGuest: "",
  });
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const inputChange = (event) => {
    const { name, value } = event.target;
    setInputvalue((prev) => ({
      ...prev,
      [name]: value,
      ...inputValue,
      [name]: value,
    }));
  };

  const acceptInvitation = async () => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/guest/accept-invitation/6593e30bd559894bc7b13622`,
        {
          ...inputValue,
          ...(inputValue.numberOfGuest === "" && { numberOfGuest: 1 }),
        }
      );
      setLoading(false);

      Swal.fire({
        title: "Invitation Success",
        text: "Thank you for accepting the invitation. Now second step, please fill your travel information by clicking on 'Send Travel Detail'. If you have not already booked your flight, don't worry, we will send you a reminder email for travel detail in few days",
        icon: "success",
      });

      setInputvalue({
        email: "",
        name: "",
        address: "",
        phone: "",
        numberOfGuest: "",
      });
    } catch (error) {
      setLoading(false);

      Swal.fire({
        title: "Error",
        text: error.response.data.message || error.response.data.error,
        icon: "error",
      });
    }

    //     let valid = true;
    //     const newErrors = {};
    //     if (!inputValue.name || inputValue.confirmname) {
    //       newErrors.name = "required your name*";
    //       valid = false;
    //     }
    //     if (
    //       !inputValue.email ||
    //       !inputValue.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    //     ) {
    //       newErrors.email = "Please enter a valid email address";
    //       valid = false;
    //     }

    // if(!inputValue.number||!inputValue.number.match(/^\+(?:[0-9] ?){6,14}[0-9]$/i))
    // {
    //      newErrors.number='Please provide valid phone number';
    //      valid=false;
    // }

    //     if (!inputValue.address || inputValue.confirmaddress) {
    //       newErrors.address = "required your address*";
    //       valid = false;
    //     }
    //     if (!inputValue.message || inputValue.confirmmessage) {
    //       newErrors.message = "required your message*";
    //       valid = false;
    //     }
    //     setErrors(newErrors);
    //     return valid;

    //   };
    // let valid=true;
    // const newErrors={}

    // if (!inputValue.phone || inputValue.phone.length <= 10) {
    //   newErrors.phone = "";
    //   valid = false;
    // }
  };

  return (
    <div id="rsvp">
      <div className=" bg-marriage bg-cover bg-center max-w-full mt-[5rem]">
        <div className="bg-black bg-opacity-80 max-w-full pt-[3rem] ">
          <div className=" w-[100%] flex flex-col items-center pt-[3rem]  ">
            <div className="flex items-center justify-center w-[15rem] h-[3rem] border-2 text-white font-display text-xl shadow-lg rounded-md">
              <h1 className=" font-display">Join Our Party</h1>
            </div>

            <Layout style={{ height: "fit-content" }}>
              <div className=" flex flex-row mobileS:flex-col items-center justify-center pt-[3rem] gap-5 mobileM:flex-col">
                <div className="flex flex-col flex-[1] w-full">
                  <h1 className="font-display flex p-[0.5rem] text-white">
                    Name
                  </h1>

                  <input
                    type="text"
                    placeholder="Your Name*"
                    onChange={inputChange}
                    name="name"
                    value={inputValue.name}
                    className="min-h-[3.5rem] rounded-md flex-[1] pl-[1rem] font-display"
                  />
                  {errors.name && (
                    <span className=" flex flex-col text-red-600 text-left text-5xl font-display text-[0.7rem] ">
                      {errors.name}
                    </span>
                  )}
                </div>

                <div className="flex flex-col flex-[1] w-full">
                  <h1 className="font-display flex  text-white">Email</h1>
                  <p className="font-display text-white text-[12px] font-thin">
                    Enter the same email in every form...
                  </p>

                  <input
                    type="text"
                    placeholder="Your Email*"
                    onChange={inputChange}
                    name="email"
                    value={inputValue.email}
                    className="min-h-[3.5rem] rounded-md flex-[1] pl-[1rem] font-display"
                  />
                  {errors.email && (
                    <span className="text-red-600 text-left text-5xl font-display text-[0.7rem] ">
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>
              <div className=" flex flex-row items-center justify-center pt-[1rem] gap-5 mobileS:flex-col mobileM:flex-col">
                {/* <select className="min-h-[3.5rem] rounded-md flex-[1] min-w-[18rem] pl-[1rem]">
                  <option>phone of cars*</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select> */}
                {/* <select className="min-h-[3.5rem] rounded-md flex-[1] min-w-[18rem] pl-[1rem]">
                  <option>I am Attending*</option>
                  <option>AI events</option>
                  <option>Wedding ceremony</option>
                  <option>Reception Party</option>
                </select> */}
                <div className="flex flex-col flex-[1] w-full">
                  <h1 className="font-display flex p-[0.5rem] text-white">
                    Address
                  </h1>
                  <input
                    type="text"
                    placeholder="Your address*"
                    onChange={inputChange}
                    name="address"
                    value={inputValue.address}
                    className="min-h-[3.5rem] rounded-md flex-[1] pl-[1rem] font-display"
                  />
                  {errors.address && (
                    <span className="text-red-600 text-left text-5xl font-display text-[0.7rem] ">
                      {errors.address}
                    </span>
                  )}
                </div>
                <div className="flex flex-col flex-[1] w-full">
                  <h1 className="font-display flex p-[0.5rem] text-white">
                    Phone Number
                  </h1>

                  <input
                    type="text"
                    placeholder="Your phone*"
                    onChange={inputChange}
                    name="phone"
                    value={inputValue.phone}
                    className="min-h-[3.5rem] rounded-md flex-[1]  pl-[1rem] font-display"
                  />
                  {errors.number && (
                    <span className="text-red-600 text-left text-5xl font-display text-[0.7rem] ">
                      {errors.number}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-center gap-5 flex-wrap ">
                <div className="flex flex-col flex-[1]">
                  <h1 className="font-display items-center text-left flex p-[0.5rem] text-white pt-[1rem]">
                    Number Of Member
                  </h1>

                  <input
                    type="number"
                    placeholder="Number Of Member"
                    name="numberOfGuest"
                    value={inputValue.numberOfGuest}
                    onChange={inputChange}
                    className="min-h-[3.5rem] rounded-md flex-[1] text-black pl-[1rem] font-display "
                  />
                </div>
              </div>

              {/* <div className="flex items-center justify-center gap-5 flex-wrap ">
             
                <div className="flex flex-col flex-[1]">
                  <h1 className="font-display items-center text-left flex p-[0.5rem] text-white pt-[1rem]">
                    Message
                  </h1>

                  <input
                    type="text"
                    placeholder="If any query right here..."
                    name="message"
                    value={inputValue.message}
                    onChange={inputChange}
                    className="min-h-[10rem] rounded-md min-w-[18rem] flex-[1] text-black pl-[1rem] pb-[6rem] font-display "
                  />
                  <div className="flex flex-col">
                    {errors.message && (
                      <span className="text-red-600 text-left font-display text-[0.7rem] ">
                        {errors.message}
                      </span>
                    )}
                  </div>
                </div>
              </div> */}

              <div className="flex mt-5 gap-3 justify-center mobileTablet:flex-col pb-[3rem] ">
                <div className="flex items-center justify-center ">
                  <button
                    className="text-white bg-teal-800 h-[3rem] w-[15rem] font-display rounded-md "
                    onClick={acceptInvitation}
                  >
                    {loading ? "Sending..." : "Accept Invitation"}
                  </button>
                </div>

                <div className="flex items-center justify-center">
                  <button
                    className="text-white bg-teal-800 h-[3rem] w-[15rem] font-display rounded-md "
                    onClick={() => navigate("travel/6593e30bd559894bc7b13622")}
                  >
                    Send Travel Detail
                  </button>
                </div>
              </div>
            </Layout>
          </div>
        </div>
      </div>
    </div>
  );
};
